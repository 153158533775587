define("d-flow-ember/helpers/is-next-disabled", ["exports", "ember"], function (exports, _ember) {
  exports.isNextDisabled = isNextDisabled;

  function isNextDisabled(params /*, hash*/) {
    if (params[0] === params[1]) {
      return "disabled";
    }
    return null;
  }

  exports["default"] = _ember["default"].Helper.helper(isNextDisabled);
});