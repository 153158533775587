define('d-flow-ember/models/store', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Object.extend({
    adapter: function adapter() {
      //return this.container.lookup('adapter:dflow');
      return _ember['default'].getOwner(this).lookup('adapter:dflow');
    },
    id: function id(id_or_params) {
      if (typeof id_or_params === "number" || typeof id_or_params === "string") {
        return id_or_params;
      } else if (typeof id_or_params === "object") {
        return id_or_params.id;
      } else {
        return null;
      }
    },
    params: function params(id_or_params, maybe_params) {
      if (typeof id_or_params === "number" || typeof id_or_params === "string") {
        return maybe_params;
      } else if (typeof id_or_params === "object") {
        delete id_or_params.id;
        return id_or_params;
      } else {
        return null;
      }
    },
    find: function find(name, id_or_params, maybe_params) {
      if (this.id(id_or_params)) {
        return this.adapter().findOne(name, this.id(id_or_params), this.params(id_or_params, maybe_params));
      } else {
        return this.adapter().findMany(name, this.params(id_or_params, maybe_params));
      }
    },
    save: function save(name, model) {
      if (model.id) {
        return this.adapter().saveUpdate(name, model.id, model);
      } else {
        return this.adapter().saveCreate(name, model);
      }
    },
    destroy: function destroy(name, id) {
      return this.adapter().destroy(name, id);
    }
  });
});