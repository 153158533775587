define('d-flow-ember/routes/jobs/show', ['exports', 'ember', 'd-flow-ember/models/job'], function (exports, _ember, _dFlowEmberModelsJob) {
  exports['default'] = _ember['default'].Route.extend({
    i18n: _ember['default'].inject.service(),
    beforeModel: function beforeModel() {
      _ember['default'].$("#app-outer").addClass("loading");
    },
    model: function model(params) {
      return this.store.find('job', params.id);
    },
    setupController: function setupController(controller, model) {
      var that = this;
      var job_model;
      _ember['default'].$("#app-outer").removeClass("loading");
      if (!!model.container) {
        job_model = model;
      } else {
        job_model = _dFlowEmberModelsJob['default'].create(_ember['default'].$.extend(model, { container: _ember['default'].getOwner(that) }));
      }
      _ember['default'].run.later(function () {
        controller.set('newFlowStep', model.current_flow_step);
      });
      controller.set('model', job_model);
      controller.set('files', null);
      controller.set('performingManualAction', false);
    },
    actions: {

      // Sets job status to 'digitizing'
      flowStepSuccessDoStuff: function flowStepSuccessDoStuff(job, flowStep) {
        var _this = this;

        this.controller.set('performingManualAction', true);
        // If save param is true, save job first
        if (flowStep.params.save === true) {
          this.store.save('job', job).then(function () {
            _this.store.find('process', job.id, { status: 'success', step: flowStep.step }).then(function () {
              _this.refresh(job.id); // Refresh children of current model
            }, function (errorObject) {
              _this.controller.set('performingManualAction', false);
              _this.controller.set('error', errorObject.error);
            });
          }, function (errorObject) {
            _this.controller.set('performingManualAction', false);
            _this.controller.set('error', errorObject.error);
          });
        } else {
          this.store.find('process', job.id, { status: 'success', step: flowStep.step }).then(function () {
            _this.refresh(job.id); // Refresh children of current model
          }, function (errorObject) {
            _this.controller.set('performingManualAction', false);
            _this.controller.set('error', errorObject.error);
          });
        }
      },

      // Deletes job from database
      deleteJob: function deleteJob(id) {
        var _this2 = this;

        // Send confirmation box before delete
        var should_delete = confirm(this.get('i18n').t("jobs.confirm_delete"));
        if (should_delete) {
          this.store.destroy('job', id).then(function () {
            _this2.transitionTo('index');
          }, function (errorObject) {
            _this2.controller.set('error', errorObject.error);
          });
        }
      },

      // Sets quarantine flag for job
      quarantineJob: function quarantineJob(job, message) {
        var _this3 = this;

        this.store.find('job', job.id + '/quarantine?message=' + message).then(function () {
          _this3.refresh(job.id); // Refresh children of current model
        }, function (errorObject) {
          job.set('quarantined', false);
          _this3.controller.set('error', errorObject.error);
        });
      },

      // Resets quarantine flag for job
      unQuarantineJob: function unQuarantineJob(job) {
        var _this4 = this;

        job.set('current_flow_step', this.controller.get('newFlowStep'));
        this.store.find('job', job.id + '/unquarantine?step=' + job.current_flow_step).then(function () {
          _this4.refresh(job.id); // Refresh children of current model
        }, function (errorObject) {
          job.set('quarantined', true);
          _this4.controller.set('error', errorObject.error);
        });
      },

      // Resets quarantine flag for job
      setFlowStep: function setFlowStep(job, recreateFlow) {
        var _this5 = this;

        job.set('current_flow_step', this.controller.get('newFlowStep'));
        this.store.find('job', job.id + '/new_flow_step?step=' + job.current_flow_step + '&recreate_flow=' + recreateFlow).then(function () {
          _this5.refresh(job.id); // Refresh children of current model
        }, function (errorObject) {
          _this5.controller.set('error', errorObject.error);
        });
      },

      // Restarts job, sets to first status and moves packagefiles to trash
      restartJob: function restartJob(job, message, recreateFlow) {
        var _this6 = this;

        job.set('message', message);
        this.store.find('job', job.id + '/restart?message=' + message + "&recreate_flow=" + recreateFlow).then(function () {
          _this6.refresh(job.id); // Refresh children of current model
        }, function (errorObject) {
          _this6.controller.set('error', errorObject.error);
        });
      },

      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });
});