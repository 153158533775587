define('d-flow-ember/routes/node/show/jobs/source', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {

  // console.log(model.roles);
  // export default Ember.Route.extend({
  //   model: function(params) {
  //     return this.store.find('source');
  //   }
  // });

  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    model: function model() {
      var node = this.modelFor('node.show').treenode;
      return { treenode_id: node.id }; // Beginning with this data
    },
    setupController: function setupController(controller, model) {
      controller.set('hasFetchedData', false);
      controller.set('model', model);
      controller.set('model.dc', {});
    },
    actions: {
      fetchSource: function fetchSource(model) {
        var that = this;
        this.store.find('source', model.source, { catalog_id: model.catalog_id, dc: model.dc }).then(function (source_data) {

          console.log(source_data.duplicate);
          if (!source_data.duplicate || source_data.duplicate && confirm('Detta id finns redan i dFLow, vill du gå vidare ändå?')) {
            that.controller.set('model.catalog_id', source_data.catalog_id);
            that.controller.set('model.title', source_data.title);
            that.controller.set('model.author', source_data.author);
            that.controller.set('model.xml', source_data.xml);
            that.controller.set('model.metadata', source_data.metadata);
            that.controller.set('model.is_periodical', source_data.is_periodical);
            that.controller.set('model.source_label', source_data.source_label);
            that.controller.set('model.flow_parameters', {});
            that.controller.set('error', null);
            that.controller.set('hasFetchedData', true);
            that.transitionTo('node.show.jobs.source.new');
          }
        }, function (errorObject) {
          that.controller.set('error', errorObject.error);
        });
      },

      clearFlags: function clearFlags() {
        this.controller.set('hasFetchedData', false);
      }
    }
  });
});
/*
 * source.js
 */