define('d-flow-ember/routes/jobs/index', ['exports', 'ember', 'd-flow-ember/models/job'], function (exports, _ember, _dFlowEmberModelsJob) {
  exports['default'] = _ember['default'].Route.extend({
    queryParams: {
      page: { refreshModel: true },
      query: { refreshModel: true },
      quarantined: { refreshModel: true },
      state: { refreshModel: true }
    },
    model: function model(params) {
      if (!params.page) {
        params.page = 1;
      }
      return this.store.find('job', params);
    },
    setupController: function setupController(controller, model) {
      var that = this;
      var jobs = _ember['default'].A([]);
      model.forEach(function (job) {
        jobs.pushObject(_dFlowEmberModelsJob['default'].create(_ember['default'].$.extend(job, { container: _ember['default'].getOwner(that) })));
      });
      controller.set('model', jobs);
      controller.set('model.meta', model.meta);

      if (controller.get('page') > controller.get('model.meta.pagination.pages')) {
        controller.transitionToRoute('jobs.index', { queryParams: { page: 1 } });
        controller.set('page', 1);
      }
    }
  });
});