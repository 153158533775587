define('d-flow-ember/components/icon-link', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    i18n: _ember['default'].inject.service(),
    tagName: 'i',
    text: '',
    classNames: ['fa'],
    attributeBindings: ['title'],
    title: _ember['default'].computed('title', function () {
      if (this.get('titleKey')) {
        return this.get('i18n').t(this.get('titleKey'));
      } else {
        return '';
      }
    })
  });
});