define('d-flow-ember/routes/jobs/show/edit', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
	exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
		model: function model() {
			// get the model data from the upstream source form
			return this.modelFor('jobs.show');
		},
		//setupController: function(controller, model) {
		//  var that = this;
		//  controller.set('model', Job.create(Ember.$.extend(model, {container: Ember.getOwner(that)})));
		//},
		actions: {
			createSuccess: function createSuccess() {
				this.send('refreshModel');
				this.transitionTo('jobs.show');
			},
			createAbort: function createAbort() {
				this.transitionTo('jobs.show');
			}
		}
	});
});