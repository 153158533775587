define('d-flow-ember/app', ['exports', 'ember', 'd-flow-ember/resolver', 'ember-load-initializers', 'd-flow-ember/config/environment'], function (exports, _ember, _dFlowEmberResolver, _emberLoadInitializers, _dFlowEmberConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _dFlowEmberConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _dFlowEmberConfigEnvironment['default'].podModulePrefix,
    Resolver: _dFlowEmberResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _dFlowEmberConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});