define('d-flow-ember/router', ['exports', 'ember', 'd-flow-ember/config/environment'], function (exports, _ember, _dFlowEmberConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _dFlowEmberConfigEnvironment['default'].locationType,
    rootURL: _dFlowEmberConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('statistics');
    this.route('queuemanager');
    this.route('node', function () {
      this.route('show', { path: '/:node_id' }, function () {
        this.route('new');
        this.route('edit', { path: '/edit/:id' });
        this.route('jobs', function () {
          this.route('edit', { path: '/edit/:id' });
          this.route('import');
          this.route('source', function () {
            this.route('new');
          });
        });
      });
    });
    this.route('login');
    this.route('users', function () {
      this.route('index', { path: '/' }, function () {
        this.route('new');
        this.route('edit', { path: '/edit/:id' });
      });
    });
    this.route('jobs', function () {
      this.route('show', { path: '/:id' }, function () {
        this.route('edit');
      });
      this.route('queue');
    });
    this.route('flows', function () {
      this.route('show', { path: '/:id' }, function () {
        this.route('edit');
      });
      this.route("new");
    });
  });

  exports['default'] = Router;
});