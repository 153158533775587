define('d-flow-ember/controllers/jobs/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    application: _ember['default'].inject.controller(),
    queryParams: ['page', 'query', 'quarantined', 'state'],
    stateSelection: _ember['default'].computed.alias('application.stateSelection'),
    page: 1,
    query: "",
    state: null,
    quarantined: ""
  });
});