define("d-flow-ember/helpers/is-previous-disabled", ["exports", "ember"], function (exports, _ember) {
  exports.isPreviousDisabled = isPreviousDisabled;

  function isPreviousDisabled(params /*, hash*/) {
    if (params[0] === 1) {
      return "disabled";
    }
    return null;
  }

  exports["default"] = _ember["default"].Helper.helper(isPreviousDisabled);
});