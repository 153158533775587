define('d-flow-ember/controllers/queuemanager', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    meta: {},
    lastFlowStepArray: _ember['default'].computed('model.last_flow_step', function () {
      var array = _ember['default'].A();
      array.pushObject(this.get('model.last_flow_step'));
      return array;
    }),
    abortedAt: _ember['default'].computed('model.aborted_at', function () {
      if (this.get('model.aborted_at')) {
        return moment(this.get('model.aborted_at')).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return "";
      }
    }),

    startedAt: _ember['default'].computed('model.started_at', function () {
      if (this.get('model.started_at')) {
        return moment(this.get('model.started_at')).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return "";
      }
    }),

    finishedAt: _ember['default'].computed('model.finished_at', function () {
      if (this.get('model.finished_at')) {
        return moment(this.get('model.finished_at')).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return "";
      }
    }),

    canStart: _ember['default'].computed('meta.can_start', 'disable', function () {
      if (this.get('meta.can_start') && !this.get('disable')) {
        return true;
      } else {
        return false;
      }
    }),

    canStop: _ember['default'].computed('meta.can_stop', 'disable', function () {
      if (this.get('meta.can_stop') && !this.get('disable')) {
        return true;
      } else {
        return false;
      }
    })
  });
});