define('d-flow-ember/mixins/in-view-port', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    scrollTimeout: 100,
    boundingClientRect: 0,
    windowHeight: 0,
    windowWidth: 0,
    enteredViewport: Em.computed('boundingClientRect', 'windowHeight', 'windowWidth', function () {
      var rect, windowHeight, windowWidth;
      rect = this.get('boundingClientRect');
      windowHeight = this.get('windowHeight');
      windowWidth = this.get('windowWidth');
      //console.log(rect.height)
      return rect.top >= -200 - rect.height && rect.left >= 0 && rect.bottom <= windowHeight + 200 + rect.height && rect.right <= windowWidth;
    }),
    exitedViewport: Em.computed.not('enteredViewport'),
    _updateBoundingClientRect: function _updateBoundingClientRect() {
      var el;
      el = this.$()[0];
      this.set('boundingClientRect', el.getBoundingClientRect());
    },
    _setup: (function () {
      return Em.run.scheduleOnce('afterRender', this, function () {
        this._updateBoundingClientRect();
        this.set('windowHeight', window.innerHeight || document.documentElement.clientHeight);
        this.set('windowWidth', window.innerWidth || document.documentElement.clientWidth);
      });
    }).on('didInsertElement'),
    _scrollHandler: function _scrollHandler() {
      return Em.run.debounce(this, '_updateBoundingClientRect', this.get('scrollTimeout'));
    },
    _bindScroll: (function () {
      var scrollHandler;
      scrollHandler = this._scrollHandler.bind(this);
      _ember['default'].$(document).on('touchmove.scrollable', scrollHandler);
      _ember['default'].$(window).on('scroll.scrollable', scrollHandler);
    }).on('didInsertElement'),
    _unbindScroll: (function () {
      _ember['default'].$(window).off('.scrollable');
      _ember['default'].$(document).off('.scrollable');
    }).on('willDestroyElement')
  });
});