define('d-flow-ember/controllers/flows/show/edit', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    modes: ['tree', 'code'],
    steps_mode: 'code',
    parameters_mode: 'code',
    folder_paths_mode: 'code',

    actions: {
      save: function save(model) {
        var that = this;
        this.set('savingMessage', 'Sparar...');
        this.store.save('flow', model).then(function () {
          that.set('errors', null);
          that.set('savingMessage', 'Sparat!');
          that.send('refreshApplication');
        }, function (response) {
          that.set('errors', response.error.errors);
          that.set('savingMessage', 'Kunde inte spara!');
        });
      }

    }

  });
});