define("d-flow-ember/templates/components/state-groups", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 299
          }
        },
        "moduleName": "d-flow-ember/templates/components/state-groups.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("p");
        dom.setAttribute(el1, "class", "navbar-text");
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "navbar-btn label label-default");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(": ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "navbar-btn label label-warning");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(": ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "navbar-btn label label-success");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(": ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [0]);
        var element2 = dom.childAt(element0, [1]);
        var element3 = dom.childAt(element0, [2]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(element1, 0, 0);
        morphs[1] = dom.createMorphAt(element1, 2, 2);
        morphs[2] = dom.createMorphAt(element2, 0, 0);
        morphs[3] = dom.createMorphAt(element2, 2, 2);
        morphs[4] = dom.createMorphAt(element3, 0, 0);
        morphs[5] = dom.createMorphAt(element3, 2, 2);
        return morphs;
      },
      statements: [["inline", "t", ["jobs.states.start"], [], ["loc", [null, [1, 68], [1, 93]]], 0, 0], ["content", "start", ["loc", [null, [1, 95], [1, 104]]], 0, 0, 0, 0], ["inline", "t", ["jobs.states.inProgress"], [], ["loc", [null, [1, 156], [1, 186]]], 0, 0], ["content", "inProgress", ["loc", [null, [1, 188], [1, 202]]], 0, 0, 0, 0], ["inline", "t", ["jobs.states.done"], [], ["loc", [null, [1, 254], [1, 278]]], 0, 0], ["content", "done", ["loc", [null, [1, 280], [1, 288]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});