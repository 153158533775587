define('d-flow-ember/routes/users/index/new', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return {}; // Data to include in create form
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('error', null);
    },
    actions: {
      createUser: function createUser(model) {
        var that = this; // To be used in nested functions
        this.store.save('user', model).then(
        // Success function
        function () {
          that.send('refreshModel'); // Refresh children of current model
          that.transitionTo('users.index');
        },
        // Failed function
        function (errorObject) {
          that.controller.set('error', errorObject.error);
        });
      }
    }
  });
});