define('d-flow-ember/routes/statistics', ['exports', 'ember'], function (exports, _ember) {
		exports['default'] = _ember['default'].Route.extend({

				setupController: function setupController(controller, model) {

						// Tweakable "constant"
						controller.set('pollInterval', 1000); // milliseconds

						// Non-tweakable "constant"
						controller.set('dateFormat', 'YYYY-MM-DD');

						// Initialization
						controller.set('pollCounter', 0);
						controller.set('error', null);
				}
		});
});