define('d-flow-ember/components/job-row', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service(),
    tagName: 'tr',
    showTree: true,
    showWorkOrder: true,
    classNameBindings: ['isDone:success', 'isError:danger', 'isProcessing:info', 'isWaitingForAction:warning'],

    isNotStarted: _ember['default'].computed('job.main_status', function () {
      return this.get('job.main_status') === 'NOT_STARTED';
    }),
    isDone: _ember['default'].computed('job.main_status', function () {
      return this.get('job.main_status') === 'DONE';
    }),
    isError: _ember['default'].computed('job.main_status', function () {
      return this.get('job.main_status') === 'ERROR';
    }),
    isProcessing: _ember['default'].computed('job.main_status', function () {
      return this.get('job.main_status') === 'PROCESSING';
    }),
    isWaitingForAction: _ember['default'].computed('job.main_status', function () {
      return this.get('job.main_status') === 'WAITING_FOR_ACTION';
    })
  });
});