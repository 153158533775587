define('d-flow-ember/components/flow-step', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'tr',
    performingAction: false,

    classNameBindings: ['isNotActivated:default', 'isActivated:warning', 'isRunning:info', 'isFinished:success'],

    isNotActivated: _ember['default'].computed('flowStep.entered_at', function () {
      return !this.get('flowStep.entered_at');
    }),

    isFinished: _ember['default'].computed('flowStep.finished_at', function () {
      return !!this.get('flowStep.finished_at');
    }),

    isRunning: _ember['default'].computed('flowStep.started_at', function () {
      return !!this.get('flowStep.started_at') && !this.get('isFinished');
    }),

    isActivated: _ember['default'].computed('flowStep.entered_at', function () {
      return !!this.get('flowStep.entered_at') && !this.get('isRunning') && !this.get('isFinished');
    }),

    enteredAt: _ember['default'].computed('flowStep.entered_at', function () {
      if (this.get('flowStep.entered_at')) {
        return moment(this.get('flowStep.entered_at')).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return "";
      }
    }),

    startedAt: _ember['default'].computed('flowStep.started_at', function () {
      if (this.get('flowStep.started_at')) {
        return moment(this.get('flowStep.started_at')).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return "";
      }
    }),

    finishedAt: _ember['default'].computed('flowStep.finished_at', function () {
      if (this.get('flowStep.finished_at')) {
        return moment(this.get('flowStep.finished_at')).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return "";
      }
    }),

    paramsString: _ember['default'].computed('flowStep.params', function () {
      return JSON.stringify(this.get('flowStep.params'));
    }),

    flowStepSuccessAction: 'flowStepSuccess',

    actions: {
      flowStepSuccess: function flowStepSuccess(flowStep) {
        this.set('performingAction', true);
        this.sendAction('flowStepSuccessAction', flowStep);
      }
    }
  });
});