define('d-flow-ember/components/print-link', ['exports', 'ember', 'd-flow-ember/config/environment'], function (exports, _ember, _dFlowEmberConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({
    i18n: _ember['default'].inject.service(),
    tagName: 'a',
    attributeBindings: ['target', 'href', 'title'],
    target: '_blank',
    classNameBindings: ['isButton:btn', 'isButton:navbar-btn'],
    isButton: (function () {
      return this.get('type') === 'button';
    }).property('type'),
    isIcon: (function () {
      return this.get('type') === 'icon';
    }).property('type'),
    href: (function () {
      return _dFlowEmberConfigEnvironment['default'].APP.serviceURL + '/assets/work_order/' + this.get('jobId') + '.pdf';
    }).property('jobId'),
    title: (function () {
      if (this.get('titleKey')) {
        return this.get('i18n').t(this.get('titleKey'));
      } else {
        return '';
      }
    }).property('titleKey')
  });
});