define('d-flow-ember/helpers/is-current-page', ['exports', 'ember'], function (exports, _ember) {
  exports.isCurrentPage = isCurrentPage;

  function isCurrentPage(params /*, hash*/) {
    if (params[0] === params[1]) {
      return 'active';
    }
    return null;
  }

  exports['default'] = _ember['default'].Helper.helper(isCurrentPage);
});