define('d-flow-ember/controllers/flows/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    actions: {
      create: function create() {
        var that = this;
        var flow = { name: moment().format('YYYY-MM-DD_HH_mm_ss') + "_NEW_FLOW" };
        this.store.save('flow', flow).then(function (response) {
          that.transitionToRoute('flows.show.edit', response.id);
        });
      }
    }
  });
});