define('d-flow-ember/components/flow-table', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'div',

    flowStepsSorted: _ember['default'].computed('flowSteps', function () {
      return this.get('flowSteps').sortBy('step');
    }),

    flowStepSuccessAction: 'flowStepSuccess',

    actions: {
      flowStepSuccess: function flowStepSuccess(id, step) {
        this.sendAction('flowStepSuccessAction', id, step);
      }
    }

  });
});