define('d-flow-ember/initializers/inject-store', ['exports', 'd-flow-ember/models/store'], function (exports, _dFlowEmberModelsStore) {
  exports['default'] = {
    name: 'inject-store',
    initialize: function initialize(app) {
      app.register('store:main', _dFlowEmberModelsStore['default']);
      app.inject('route', 'store', 'store:main');
      app.inject('controller', 'store', 'store:main');
      app.inject('component', 'store', 'store:main');
    }
  };
});