define('d-flow-ember/components/tree-item', ['exports', 'ember', 'd-flow-ember/config/environment'], function (exports, _ember, _dFlowEmberConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service(),
    tagName: 'div',
    isExpanded: false,
    parentPath: '',

    byteString: _ember['default'].computed('item.size', function () {
      var size = this.get('item.size');
      var i = -1;
      var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
      do {
        size = size / 1024;
        i++;
      } while (size > 1024);

      return Math.max(size, 0.1).toFixed(1) + byteUnits[i];
    }),

    extension: _ember['default'].computed('item.name', function () {
      return this.get('item.name').split('.').pop();
    }),

    isImage: _ember['default'].computed('extension', function () {
      return ['jpg', 'jpeg', 'tif', 'tiff', 'png', 'jp2'].contains(this.get('extension'));
    }),

    isPdf: _ember['default'].computed('extension', function () {
      return ['pdf'].contains(this.get('extension'));
    }),

    isText: _ember['default'].computed('extension', function () {
      return ['xml', 'txt'].contains(this.get('extension'));
    }),

    isFile: _ember['default'].computed('isImage', 'isPdf', 'isText', function () {
      return !this.isImage && !this.isPdf && !this.isText;
    }),

    path: _ember['default'].computed('parentPath', 'item.name', function () {
      return this.get('parentPath') + this.get('item.name') + '/';
    }),

    fileUrl: _ember['default'].computed('item', function () {
      var token = this.get('session.data.authenticated.token');
      return _dFlowEmberConfigEnvironment['default'].APP.serviceURL + '/assets/file?file_path=' + this.get('parentPath') + this.get('item.name') + '&token=' + token;
    }),

    icon: _ember['default'].computed('isImage', 'isPdf', 'isFile', 'isText', function () {
      if (this.get('isImage')) {
        return 'fa-file-image-o';
      } else if (this.get('isPdf')) {
        return 'fa-file-pdf-o';
      } else if (this.get('isText')) {
        return 'fa-file-text-o';
      } else if (this.get('isFile')) {
        return 'fa-file-o';
      }
    }),

    actions: {
      toggle: function toggle() {
        this.toggleProperty('isExpanded');
      }
    }
  });
});