define('d-flow-ember/routes/users/index', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    model: function model() {
      return this.store.find('user');
    },
    actions: {
      // Refresh model to be called from other nested routes/controllers
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });
});