define('d-flow-ember/components/parameter-input', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    initValue: (function () {
      this.set('value', this.get('values.' + this.get('parameter.name')));
    }).on('init'),

    isRadio: _ember['default'].computed.equal('parameter.type', 'radio'),
    isText: _ember['default'].computed.equal('parameter.type', 'text'),
    prompt: _ember['default'].computed.equal('parameter.prompt', true),

    valueObserver: _ember['default'].observer('value', function () {
      this.set('values.' + this.get('parameter.name'), this.get('value'));
    }),

    optionList: _ember['default'].computed('parameter.options', function () {
      return this.get('parameter.options').map(function (option) {
        if (typeof option === "string") {
          return {
            value: option,
            label: option
          };
        } else {
          return option;
        }
      });
    })
  });
});