define('d-flow-ember/routes/node/show/jobs/import', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    model: function model() {
      return {
        flow_parameters: {}
      };
    },
    setupController: function setupController(controller, model) {
      if (controller.get('preventUpdate')) {
        controller.set('preventUpdate', false);
      } else {
        controller.set('model', model);
        controller.set('error', null);
        controller.set('process_id', null);
      }
    }
  });
});