define('d-flow-ember/controllers/jobs/show', ['exports', 'ember', 'd-flow-ember/config/environment'], function (exports, _ember, _dFlowEmberConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service(),
    application: _ember['default'].inject.controller(),
    flowSelection: _ember['default'].computed.alias('application.flowSelection'),
    flows: _ember['default'].computed.alias('application.flows'),
    open: '',
    setFlowParams: _ember['default'].computed.equal('model.flow_step.process', 'ASSIGN_FLOW_PARAMETERS'),

    metadataIsOpen: _ember['default'].computed.equal('open', 'metadata'),
    jobActivityIsOpen: _ember['default'].computed.equal('open', 'job_activity'),
    filesIsOpen: _ember['default'].computed.equal('open', 'files'),
    flowIsOpen: _ember['default'].computed.equal('open', 'flow'),
    pubLogIsOpen: _ember['default'].computed.equal('open', 'pub_log'),
    recreateFlow: true,

    numberOfPages: _ember['default'].computed('model', function () {
      return this.get("model.package_metadata.image_count");
    }),

    showMetadata: _ember['default'].computed('model.flow_step', function () {
      if (this.get("model.flow_step.process") === "ASSIGN_METADATA" && this.get("model.flow_step.params.manual")) {
        return true;
      }
      return false;
    }),

    pdfUrl: _ember['default'].computed('model', function () {
      var token = this.get('session.data.authenticated.token');
      return _dFlowEmberConfigEnvironment['default'].APP.serviceURL + '/assets/file?file_path=' + this.get('model.flow_step.parsed_params.pdf_file_path') + '&token=' + token;
    }),

    currentFlow: _ember['default'].computed('model.flow_id', function () {
      return this.get('flows').findBy('id', this.get('model.flow_id'));
    }),

    isPriorityNormal: _ember['default'].computed('model.priority', function () {
      return this.get('model.priority') == 2;
    }),
    isPriorityHigh: _ember['default'].computed('model.priority', function () {
      return this.get('model.priority') == 1;
    }),
    isPriorityLow: _ember['default'].computed('model.priority', function () {
      return this.get('model.priority') == 3;
    }),

    flowStepItems: _ember['default'].computed('model.flow', 'model.flow_steps', 'model.current_flow_step', function () {
      var flowStepItems = [];
      for (var y = 0; y < this.get('model.flow_steps').sortBy('step').length; y++) {
        var flowStep = this.get('model.flow_steps')[y];
        var prefix = '';
        if (flowStep.finished_at) {
          prefix = '-';
        }
        if (flowStep.step === this.get('model.current_flow_step')) {
          prefix = '*';
        }
        var label = prefix + flowStep.step + ". " + flowStep.description;
        var item = { label: label, value: parseInt(flowStep.step) };
        flowStepItems.pushObject(item);
      }
      return flowStepItems.sortBy('value');
    }),

    actions: {
      flowStepSuccess: function flowStepSuccess(flowStep) {
        this.send('flowStepSuccessDoStuff', this.get('model'), flowStep);
      },
      setOpen: function setOpen(string) {
        var _this = this;

        this.set('open', string);
        if (string === 'files') {
          if (!this.get('files')) {
            this.set('filesLoading', true);
            var job_id = this.get('model.id');
            this.store.find('file', { job_id: job_id }).then(function (files) {
              _this.set('files', files);
              _this.set('filesLoading', false);
            });
          }
        }
      }
    }

  });
});