define('d-flow-ember/controllers/node/show', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service(),
    application: _ember['default'].inject.controller(),
    stateSelection: _ember['default'].computed.alias('application.stateSelection'),
    queryParams: ['page', 'query', 'quarantined', 'state'],
    page: 1,
    query: "",
    quarantined: "",
    state: null,
    isRoot: _ember['default'].computed.empty('model.id')
  });
});