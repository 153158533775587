define('d-flow-ember/components/step-row', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'tr',

    enteredAt: _ember['default'].computed('step.entered_at', function () {
      if (this.get('step.entered_at')) {
        return moment(this.get('step.entered_at')).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return "";
      }
    }),

    sinceEntered: _ember['default'].computed('step.entered_at', function () {
      if (this.get('step.entered_at')) {
        return moment(this.get('step.entered_at')).fromNow();
      } else {
        return "";
      }
    }),

    startedAt: _ember['default'].computed('step.started_at', function () {
      if (this.get('step.started_at')) {
        return moment(this.get('step.started_at')).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return "";
      }
    }),

    sinceStarted: _ember['default'].computed('step.started_at', function () {
      if (this.get('step.started_at')) {
        return moment(this.get('step.started_at')).fromNow();
      } else {
        return "";
      }
    })
  });
});