define('d-flow-ember/routes/node/show', ['exports', 'ember', 'd-flow-ember/models/job'], function (exports, _ember, _dFlowEmberModelsJob) {
  exports['default'] = _ember['default'].Route.extend({
    queryParams: {
      page: { refreshModel: true },
      query: { refreshModel: true },
      state: { refreshModel: true }
    },
    model: function model(params) {
      if (!params.page) {
        params.page = 1;
      }
      return _ember['default'].RSVP.hash({
        treenode: this.store.find('treenode', params.node_id, {
          show_children: true,
          show_breadcrumb: true
        }),
        jobs: this.store.find('job', params)
      });
    },
    setupController: function setupController(controller, model) {
      var that = this;
      model.treenode.breadcrumb.push({ name: model.treenode.name });
      controller.set('model', model.treenode);
      if (model.treenode.id) {
        var jobs = _ember['default'].A([]);
        model.jobs.forEach(function (job) {
          jobs.pushObject(_dFlowEmberModelsJob['default'].create(_ember['default'].$.extend(job, { container: _ember['default'].getOwner(that) })));
        });
        controller.set('model.jobs', jobs);
        controller.set('model.jobs.meta', model.jobs.meta);
      }
    },
    actions: {
      // Refresh model to be called from other nested routes/controllers
      refreshModel: function refreshModel(modelId) {
        this.refresh(modelId);
      }
    }
  });
});