define('d-flow-ember/authenticators/gub', ['exports', 'ember', 'ember-simple-auth/authenticators/base', 'd-flow-ember/config/environment', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthAuthenticatorsBase, _dFlowEmberConfigEnvironment, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
	exports['default'] = _emberSimpleAuthAuthenticatorsBase['default'].extend({
		restore: function restore(properties) {
			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				_ember['default'].$.ajax({
					type: 'GET',
					url: _dFlowEmberConfigEnvironment['default'].APP.authenticationBaseURL + '/' + properties.token
				}).then(function () {
					resolve(properties);
				}, function () {
					reject();
				});
			});
		},
		authenticate: function authenticate(credentials) {

			var authCredentials = {};
			if (credentials.cas_ticket && credentials.cas_service) {
				authCredentials = credentials;
			} else {
				authCredentials = {
					username: credentials.identification,
					password: credentials.password
				};
			}
			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				_ember['default'].$.ajax({
					type: 'POST',
					url: _dFlowEmberConfigEnvironment['default'].APP.authenticationBaseURL,
					data: JSON.stringify(authCredentials),
					contentType: 'application/json'
				}).then(function (response) {
					var token = response.access_token;
					_ember['default'].run(function () {
						resolve({
							authenticated: true,
							token: token,
							username: response.user.username,
							name: response.user.name,
							can_view_users: _ember['default'].$.inArray('view_users', response.user.role.rights) !== -1,
							can_manage_tree: _ember['default'].$.inArray('manage_tree', response.user.role.rights) !== -1,
							can_manage_tree_root: _ember['default'].$.inArray('manage_tree_root', response.user.role.rights) !== -1,
							can_manage_jobs: _ember['default'].$.inArray('manage_jobs', response.user.role.rights) !== -1,
							can_manage_statistics: _ember['default'].$.inArray('manage_statistics', response.user.role.rights) !== -1
						});
					});
				}, function (xhr) {
					_ember['default'].run(function () {
						reject(xhr.responseJSON.error);
					});
				});
			});
		},
		invalidate: function invalidate() {
			return new _ember['default'].RSVP.Promise(function (resolve) {
				resolve();
			});
		}
	});

	_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'].reopen({
		beforeModel: function beforeModel(transition) {
			var session = this.get('session');
			var token = null;
			if (session) {
				token = session.get('data.authenticated.token');
			}
			_ember['default'].$.ajax({
				type: 'GET',
				url: _dFlowEmberConfigEnvironment['default'].APP.authenticationBaseURL + '/' + token
			}).then(null, function () {
				session.invalidate();
			});
			return this._super(transition);
		}
	});
});