define('d-flow-ember/routes/jobs/queue', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.store.find('queue');
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);
    },

    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });
});