define("d-flow-ember/helpers/markdown-text", ["exports", "ember"], function (exports, _ember) {
  exports.markdownText = markdownText;

  function markdownText(params) {
    var value = params[0];
    value = value || "";
    value = value.replace(/\n/g, '  \n');
    return new _ember["default"].Handlebars.SafeString(markdown.toHTML(value));
  }

  exports["default"] = _ember["default"].Helper.helper(markdownText);
});