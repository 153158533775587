define('d-flow-ember/routes/users/index/edit', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    i18n: _ember['default'].inject.service(),
    model: function model(params) {
      return this.store.find('user', params.id);
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('error', null);
    },
    actions: {
      saveUser: function saveUser(model) {
        var that = this; // To be used in nested functions
        this.store.save('user', model).then(
        // Success function
        function () {
          that.send('refreshModel'); // Refresh children of current model
          that.transitionTo('users.index');
        },
        // Failed function
        function (errorObject) {
          that.controller.set('error', errorObject.error);
        });
      },
      deleteUser: function deleteUser(id) {
        var that = this;
        // Send confirmation box before delete
        var should_delete = confirm(this.get('i18n').t("users.confirm_delete"));
        if (should_delete) {
          this.store.destroy('user', id).then(function () {
            that.send('refreshModel');
            that.transitionTo('users.index');
          }, function (errorObject) {
            that.controller.set('error', errorObject.error);
          });
        }
      }
    }
  });
});