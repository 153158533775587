define('d-flow-ember/controllers/application', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service(),
    ticket: null,
    queryParams: ["ticket"],

    formatNoOfJobsInQuarantine: _ember['default'].computed('jobsInQuarantine', function () {
      if (this.get('jobsInQuarantine') > 0) {
        return " (" + this.get('jobsInQuarantine') + ")";
      } else {
        return "";
      }
    }),
    anyJobInQuarantine: _ember['default'].computed('jobsInQuarantine', function () {
      return this.get('jobsInQuarantine') > 0;
    })
  });
});