define('d-flow-ember/routes/node/show/jobs/source/new', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    beforeModel: function beforeModel() {
      if (this.modelFor('node.show.jobs.source').catalog_id === undefined) {
        this.transitionTo('node.show.jobs.source');
      }
    },
    model: function model() {
      // get the model data from the upstream source form
      return this.modelFor('node.show.jobs.source');
    },

    deactivate: function deactivate() {
      this.send('clearFlags');
    },

    actions: {
      createSuccess: function createSuccess() {
        this.send('refreshModel');
        this.transitionTo('node.show');
      },
      createAbort: function createAbort() {
        this.transitionTo('node.show');
      }
    }
  });
});