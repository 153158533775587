define('d-flow-ember/components/metadata-setter', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    page_type: undefined,
    page_content: undefined,
    select_all: true,
    select_odd: true,
    select_even: true,
    latestSelected: null,
    currentPage: 1,
    per_page_items: 500,
    pages_in_number_arr: [],

    metadataImages: _ember['default'].computed('packageMetadata.images.[]', 'currentPage', function () {
      this.deselectAllImages();
      return this.paginator(this.get('packageMetadata.images'), this.get('currentPage'), this.get('per_page_items'));
    }),
    hasSelected: _ember['default'].computed('packageMetadata.images.@each.selected', function () {
      if (this.get("packageMetadata.images")) {
        return this.get("packageMetadata.images").filter(function (image) {
          return image.selected;
        }).length;
      }
    }),

    paginator: function paginator(items, current_page, per_page_items) {
      var page = current_page || 1,
          per_page = per_page_items || 10,
          offset = (page - 1) * per_page,
          paginatedItems = items.slice(offset).slice(0, per_page_items),
          total_pages = Math.ceil(items.length / per_page);
      this.set("pages_in_number_arr", this.range(1, total_pages));
      return {
        page: page,
        per_page: per_page,
        pre_page: page - 1 ? page - 1 : null,
        next_page: total_pages > page ? page + 1 : null,
        total: items.length,
        total_pages: total_pages,
        data: paginatedItems
      };
    },

    range: function range(start, end) {
      return Array(end - start + 1).fill().map(function (_, idx) {
        return start + idx;
      });
    },

    setup: (function () {
      $('[data-toggle="tooltip"]').tooltip({
        trigger: 'hover'
      });
    }).on('didRender'),

    deselectAllImages: function deselectAllImages() {
      this.get('packageMetadata.images').forEach(function (image, index) {
        _ember['default'].set(image, 'selected', false);
      });
    },

    actions: {
      /*  generatePageTypes() {
          var that = this;
          this.get('packageMetadata.images').forEach((image, index) =>{
            var even = 'Undefined';
            var odd = 'Undefined';
            var currIndex = index;
            if (this.get('startNr')) {
              if (index < this.get('startNr')-1) {
                return;
              }
              currIndex = index - this.get('startNr') + 1;
            }
            switch (this.get('sequence')) {
              case 'right-left':
                even = 'RightPage';
                odd = 'LeftPage';
                break;
              case 'left-right':
                even = 'LeftPage';
                odd = 'RightPage';
                break;
              case 'right':
                even = 'RightPage';
                odd = 'RightPage';
                break;
              case 'left':
                even = 'LeftPage';
                odd = 'LeftPage';
                break;
              default:
                even = 'Undefined';
                odd = 'Undefined';
            }
            if (currIndex % 2 === 0) {
              Ember.set(image, 'page_type', even);
            } else {
              Ember.set(image, 'page_type', odd);
            }
          })
        },*/

      specificPage: function specificPage(page) {
        this.set('currentPage', page);
      },
      previousPage: function previousPage() {
        var newPage = this.get("currentPage") - 1;
        this.set('currentPage', newPage);
      },
      nextPage: function nextPage() {
        var newPage = this.get("currentPage") + 1;
        this.set('currentPage', newPage);
      },
      saveMetaData: function saveMetaData(flowStep) {
        var r = confirm("Är du säker på att du vill spara metadatan?");
        if (r == true) {
          this.get('flowStepSuccess')(flowStep);
          $('#myModal').modal('hide');
        }
      },
      applyMetadataSequence: function applyMetadataSequence() {
        var _this = this;

        this.get('packageMetadata.images').filter(function (item) {
          return item.selected;
        }).forEach(function (image, index) {
          var even = 'Undefined';
          var odd = 'Undefined';
          var currIndex = index;
          switch (_this.get('sequence')) {
            case 'right-left':
              even = 'RightPage';
              odd = 'LeftPage';
              break;
            case 'left-right':
              even = 'LeftPage';
              odd = 'RightPage';
              break;
            default:
              even = undefined;
              odd = undefined;
          }
          if (currIndex % 2 === 0) {
            _ember['default'].set(image, 'page_type', even);
          } else {
            _ember['default'].set(image, 'page_type', odd);
          }
        });
      },
      applyMetadataPhysical: function applyMetadataPhysical() {
        var _this2 = this;

        this.get('packageMetadata.images').forEach(function (image, index) {
          if (image.selected) {
            _ember['default'].set(image, 'page_type', _this2.page_type);
            // Ember.set(image, 'page_content', this.page_content);
          }
        });
      },
      applyMetadataLogical: function applyMetadataLogical() {
        var _this3 = this;

        this.get('packageMetadata.images').forEach(function (image, index) {
          if (image.selected) {
            _ember['default'].set(image, 'page_content', _this3.page_content);
          }
        });
      },
      selectAll: function selectAll() {
        this.get('packageMetadata.images').forEach(function (image, index) {
          _ember['default'].set(image, 'selected', true);
        });
        this.set("latestSelected", null);
      },

      deselectAll: function deselectAll() {
        this.deselectAllImages();
      }

    }
  });
});