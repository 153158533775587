define('d-flow-ember/components/state-groups', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'span',
    classNames: ['navbar-link'],

    inProgress: _ember['default'].computed('stateGroups', function () {
      var actionVal = 0;
      if (this.get('stateGroups.ACTION')) {
        actionVal = this.get('stateGroups.ACTION');
      }
      var processVal = 0;
      if (this.get('stateGroups.PROCESS')) {
        processVal = this.get('stateGroups.PROCESS');
      }
      return actionVal + processVal;
    }),

    start: _ember['default'].computed('stateGroups', function () {
      var val = 0;
      if (this.get('stateGroups.START')) {
        val = this.get('stateGroups.START');
      }
      return val;
    }),

    done: _ember['default'].computed('stateGroups', function () {
      var val = 0;
      if (this.get('stateGroups.FINISH')) {
        val = this.get('stateGroups.FINISH');
      }
      return val;
    })
  });
});